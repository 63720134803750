import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 비엠스마일 모성현, 비엠스테이지 초대 대표이사로 선임",
  "| 첫 프로젝트 ‘반려동물 사물인터넷(IoT) 서비스’ 예정",
  "| 기술혁신과 고객 데이터 기반한 양 사 전문 노하우로 성과 낼 것",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/30/30_img1.png" style={{ maxWidth: "300px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">&lt;스테이지파이브-비엠스마일 합작법인 비엠스테이지 CI. &gt;</div>
    </div>
    <p>
      <br />
      <br />
      반려동물 라이프스타일 브랜드 ‘페스룸’의 비엠스마일(한국대표 모성현)과 핀다이렉트를 운영하고 있는 통신사 스테이지파이브(대표
      서상원)가 27일(월) 합작 법인 비엠스테이지를 설립했다. 해당 법인의 신임대표로는 비엠스마일의 모성현 한국대표가 선임되었다.
      모대표는 향후 비엠스마일과 비엠스테이지의 대표를 겸직하며 첫 발걸음을 떼는 비엠스테이지가 펫시장에 성공적으로 안착할 수
      있도록 힘쓸 예정이다.
      <br />
      <br />
      비엠스테이지에서 진행하게 될 첫 번째 프로젝트는 ‘반려동물 사물인터넷(IoT) 서비스’이다. 빠른 시일 내 통신기반 디바이스와 연동
      가능한 반려동물 라이프케어 플랫폼을 구축하고, 해당 플랫폼에 누적된 반려동물 데이터를 기반하여 최적화된 스마트 디바이스를
      제공한다는 계획이다. 해당 서비스의 빠른 출시를 위해 디바이스, 플랫폼, 데이터분석, 서비스에 대한 분석 및 기획을 모두 끝마친
      상태다.
      <br />
      <br />
      비엠스테이지 모성현 대표는 “기존 반려동물 케어 시장에서 소비자들의 관심이 높은 ‘자동화 서비스’를 첫 프로젝트로
      선정하였다”며, “대부분의 생활서비스 영역에서는 하나의 플랫폼을 통해 다양한 니즈를 해결하는데 익숙해져 있지만 펫 서비스
      영역은 그렇지 않은 경향이 크다. 2022년 기준 4조원 규모가 넘는 펫 시장 내 가장 스마트하고 펫팸 친화적인 통합 서비스를
      제공하는 기업으로 거듭날 것”이라고 밝혔다.
      <br />
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴CR실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2022년 6월 28일 즉시배포"
      pageInfo="*첨부사진 1매"
      title="비엠스마일-스테이지파이브 합작법인, 비엠스테이지 출범… MZ펫팸족 공략, 4조원 규모 펫 시장 내 출사표 “이제는 스마트하게 댕냥이 풀케어 하세요!”"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
